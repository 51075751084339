import { inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { storage } from '../../utils';

export enum Languages {
	EN = 'en',
	VI = 'vi',
}

export type SettingState = {
	language: Languages;
};

const initialState: SettingState = {
	language: Languages.EN,
};

export const settingStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),
	withMethods((store, translocoService = inject(TranslocoService)) => ({
		init(): void {
			patchState(store, storage.getItem('setting') ?? initialState);
		},
		changeLanguage(language: Languages): void {
			patchState(store, { language });
			storage.setItem('setting', {
				language,
			});
			translocoService.setActiveLang(language);
		},
		clear(): void {},
	})),
	withHooks({
		onInit(store) {
			store.init();
		},
		onDestroy(store) {
			store.clear();
		},
	}),
);
