import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { IdentityItems, IdentityVerificationResponse, PreviewImages, StepIdentityType } from 'app/core/types';
import { computed, inject } from '@angular/core';
import { IdentityVerificationService } from 'app/core/services';
import { catchError, EMPTY, iif, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { IdentityType } from 'app/core/enums';
import { StepIdentityKyb, StepIdentityKyc, storage } from 'app/core/utils';
import { UserKycStatus } from '../../../modules/main/kyc/kyc.const';
import { format } from 'date-fns';
import { UserFacade } from '../user/user.facade';
import { StorageObjectType } from '../../utils/storage/storage.types';

export type IdentityState = {
	data: IdentityVerificationResponse;
	loading: boolean;
	error: boolean;
	previews: PreviewImages;
	mapItems: IdentityItems[];
};

const initialState: IdentityState = {
	data: {
		userName: '',
		email: '',
		submittedAt: new Date(),
		lastUpdatedAt: new Date(),
		updatedBy: '',
		status: 0,
		note: '',
		source: '',
		rejectCount: 0,
		categories: [],
		margins: [],
		personalProfile: [],
		businessProfile: [],
	},
	loading: false,
	error: false,
	previews: {
		UtilityBillFront: '',
		IdentityCardFront: '',
		SsnFront: '',
		VoidCheckPersonalFront: '',
		CompanyLogo: '',
		IrsLetter: '',
		BusinessLicense: '',
		VoidCheckBusinessFront: '',
		Selfie: '',
	},
	mapItems: [],
};

export const identityStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),
	withMethods((store, identityVerificationService = inject(IdentityVerificationService)) => ({
		init(): void {
			patchState(store, { loading: true });

			identityVerificationService
				.getItems()
				.pipe(
					map((data) => data.filter((item) => item.type === IdentityType.Personal)),
					tap((mapItems) => patchState(store, { mapItems })),
				)
				.subscribe();

			identityVerificationService
				.getDetail()
				.pipe(
					switchMap((data) => {
						return iif(
							() => storage.getItem('kycType') === IdentityType.Business && data.businessProfile.length === 0,
							of(null),
							of(data),
						);
					}),
					tap((data) => {
						!!data ? patchState(store, { data, loading: false }) : patchState(store, { loading: false, error: true });
					}),
					catchError((error: Error) => {
						patchState(store, { loading: false, error: true });
						return throwError(() => new Error(error.message));
					}),
				)
				.subscribe();
		},

		refreshData$(): Observable<IdentityVerificationResponse> {
			this.clear();
			patchState(store, { loading: true });
			return identityVerificationService.getDetail().pipe(tap((data) => patchState(store, { data, loading: false })));
		},

		uploadPreviews(key: string, value: string): void {
			patchState(store, {
				previews: {
					...store.previews(),
					[key]: value,
				},
			});
		},

		submit$(formData: Record<string, string>): Observable<unknown> {
			const userKycItems = store.mapItems().map((item) => {
				const rawValue = formData[item.name] || '';
				const formattedValue = item.name === 'DateOfBirth' ? format(rawValue, 'MM/dd/yyyy') : rawValue;

				return {
					kycItemId: item.id,
					value: formattedValue.toString(),
				};
			});

			return identityVerificationService.submit(userKycItems);
		},

		clear(): void {
			patchState(store, initialState);
		},
	})),
	withHooks({
		onInit(store) {
			store.init();
		},
		onDestroy(store) {
			store.clear();
		},
	}),
	withComputed((store, userFacade = inject(UserFacade)) => ({
		isKyc: computed<boolean>(() => {
			const kycType: IdentityType = storage.getItem('kycType');
			return kycType === IdentityType.Personal;
		}),
		isNote: computed<boolean>(() => store.data.note() !== ''),

		contentKyb: computed<StepIdentityType[]>(() => {
			const isKyc = storage.getItem('kycType') === IdentityType.Personal;

			if (store.error() || isKyc) return [];
			const { businessProfile, personalProfile } = store.data();
			const list = StepIdentityKyb;
			for (let index = 0; index < list.length; index++) {
				const { key } = list[index];
				const items = [...businessProfile, ...personalProfile].filter((i) => key.includes(i.name));
				if (list[index].title == 'location') {
					const lat = items.find((i) => i.name === 'Latitude');
					const lng = items.find((i) => i.name === 'Longitude');
					list[index].items = [{ lat: Number(lat.value), lng: Number(lng.value) }] as any;
				} else {
					list[index].items = items.sort((a, b) => {
						return key.indexOf(a.name) - key.indexOf(b.name);
					});
				}
				list[index].hasItemInvalid = list[index].items.some((item) => item.status === UserKycStatus.Rejected);
			}
			return list;
		}),

		contentKyc: computed<StepIdentityType[]>(() => {
			const isKyc = storage.getItem('kycType') === IdentityType.Personal;
			if (store.error() || !isKyc) return [];

			const list = StepIdentityKyc;
			for (let index = 0; index < list.length; index++) {
				const { key } = list[index];
				const items = [...store.data.personalProfile()].filter((i) => key.includes(i.name));
				list[index].items = items.sort((a, b) => {
					return key.indexOf(a.name) - key.indexOf(b.name);
				});
				list[index].hasItemInvalid = list[index].items.some((item) => item.status === UserKycStatus.Rejected);
			}
			return list;
		}),
	})),
);
