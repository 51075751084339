import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { UserProfile } from 'app/core/types';
import { inject } from '@angular/core';
import { UserService } from '../../services';
import { AuthService } from '../../auth/auth.service';
import { filter, Observable, tap } from 'rxjs';

export type IdentityState = {
	data: UserProfile;
	loading: boolean;
};

const initialState: IdentityState = {
	data: {
		id: '',
		email: '',
		userName: '',
		type: 0,
		status: 0,
		createdAt: '',
		updatedAt: '',
		profile: {
			address: '',
			phoneNumber: '',
			firstName: '',
			lastName: '',
			country: {},
			postCode: {},
			createdAt: '',
			updatedAt: '',
		},
		clients: [],
		userKycStatus: 0,
		attributes: {},
	},
	loading: false,
};

export const userStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),
	withMethods((store, userService = inject(UserService)) => ({
		init(): void {
			patchState(store, { loading: true });
			userService
				.get()
				.pipe(
					filter((user) => !!user),
					tap((user) => patchState(store, { data: user, loading: false })),
				)
				.subscribe();
		},

		refreshData$(): Observable<UserProfile> {
			this.clear();
			patchState(store, { loading: true });
			return userService.get().pipe(
				filter((user) => !!user),
				tap((user) => patchState(store, { data: user, loading: false })),
			);
		},

		clear(): void {
			patchState(store, initialState);
		},
	})),
	withHooks({
		onInit(store, authService = inject(AuthService)) {
			if (authService.isAuthenticated()) store.init();
		},
		onDestroy(store) {
			store.clear();
		},
	}),
	withComputed((store) => ({})),
);
