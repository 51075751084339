import { inject, Injectable, Signal } from '@angular/core';
import { identityStore } from './identity.store';
import { DeepSignal } from '@ngrx/signals/src/deep-signal';
import { IdentityVerificationResponse, PreviewImages, StepIdentityType } from 'app/core/types';
import { forkJoin, map, Observable } from 'rxjs';
import { userStore } from '../user/user.store';

@Injectable({ providedIn: 'root' })
export class IdentityFacade {
	#store = inject(identityStore);
	#userStore = inject(userStore);

	// Selectors
	data: DeepSignal<IdentityVerificationResponse> = this.#store.data;
	loading: Signal<boolean> = this.#store.loading;
	error: Signal<boolean> = this.#store.error;
	previews: Signal<PreviewImages> = this.#store.previews;
	isKyc: Signal<boolean> = this.#store.isKyc;
	isNote: Signal<boolean> = this.#store.isNote;
	contentKyb: Signal<StepIdentityType[]> = this.#store.contentKyb;
	contentKyc: Signal<StepIdentityType[]> = this.#store.contentKyc;

	// Methods
	uploadPreviews = (key: string, value: string): void => this.#store.uploadPreviews(key, value);
	submit$ = (formData: Record<string, string>): Observable<unknown> => this.#store.submit$(formData);
	refreshData$ = (): Observable<IdentityVerificationResponse> =>
		forkJoin([this.#store.refreshData$(), this.#userStore.refreshData$()]).pipe(map(([identity, user]) => identity));
}
