import { Injectable, Signal, inject } from '@angular/core';
import {Languages, settingStore} from './setting.store';

@Injectable({ providedIn: 'root' })
export class SettingFacade {
    #store = inject(settingStore);
    currentLang: Signal<Languages> = this.#store.language;

    changeLanguage = (language: Languages): void => this.#store.changeLanguage(language);

}
