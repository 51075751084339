import { inject, Injectable, Signal } from '@angular/core';
import { userStore } from './user.store';
import { Observable } from 'rxjs';
import { UserProfile } from '../../types';

@Injectable({ providedIn: 'root' })
export class UserFacade {
	#store = inject(userStore);

	data = this.#store.data;
	loading: Signal<boolean> = this.#store.loading;

	// Methods
	refreshData$ = (): Observable<UserProfile> => this.#store.refreshData$();
}
